import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { computed, ref } from 'vue';
import { useAuchanStoresStore } from './auchanStore';
import { setErrorNotification } from '~~/common/utils/helper';

let throttleId = null;
let pendingProductIds = [];

export const useStockInfoStore = defineStore('stockInfo', () => {
  const nuxtApp = useNuxtApp();
  const auchanStore = useAuchanStoresStore();

  const gStock = ref({});
  const lStock = ref({});

  function setGlobalStock ({ productId, logisticGlobalStock, status: stockStatus = '' }) {
    gStock.value[productId] = { logisticGlobalStock, stockStatus };
  }

  function setLocalStock ({ productId, storeId, stock }) {
    if (!lStock.value[productId]) {
      lStock.value[productId] = {};
    }
    lStock.value[productId][storeId] = stock;
  }

  function fetchStock ({ productIds, details = false }) {
    const { $logger } = nuxtApp;
    try {
      if (details) {
        groupStockRequests({ productIds, details });
        return;
      }
      pendingProductIds = pendingProductIds.concat(productIds);
      clearTimeout(throttleId);
      throttleId = setTimeout(() => {
        groupStockRequests({ productIds: [...pendingProductIds], details });
        pendingProductIds = [];
      }, 300);
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  async function groupStockRequests ({ productIds, details = false }) {
    const { $api, $logger } = nuxtApp;

    try {
      if (!Array.isArray(productIds)) {
        throw new TypeError('productIds has to be an array');
      }
      if (!productIds.length) {
        throw new TypeError('productIds has to contain at least one number');
      }
      const [data] = await Promise.all(function* () {
        if (productIds.length === 1) {
          yield $api.$get(`stock_infos/${productIds[0]}/${Number(details)}`).then(e => [e]);
          if (details) {
            yield auchanStore.fetchAuchanStores();
          }
        } else {
          yield $api.$get('stock_infos', {
            params: {
              productId: Array.from(new Set(productIds)),
            },
          });
        }
      }());
      data.forEach((prodStock) => {
        setGlobalStock(prodStock);
        for (const s of StockGenerator(auchanStore.stores, prodStock)) {
          setLocalStock({ productId: prodStock.productId, storeId: s.storeId, stock: new StockType(s) });
        }
      });
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  const getGlobalStockInfo = computed(() => (productId) => {
    return gStock.value?.[productId] || { logisticGlobalStock: 0 };
  });
  const getLocalStockInfo = computed(() => (productId, storeId = 'current') => {
    return lStock.value?.[productId]?.[storeId] || new StockType({ storeStock: 0, logisticStock: 0 });
  });
  const getFullStockInfo = computed(() => (productId, storeId = 'current') => {
    const { store, logistic } = getLocalStockInfo.value(productId, storeId);
    return new StockType({
      storeStock: store.stock,
      logisticStock: logistic.stock + (gStock.value?.[productId]?.logisticGlobalStock || 0),
    });
  });

  return {
    gStock,
    lStock,
    setGlobalStock,
    setLocalStock,
    fetchStock,
    groupStockRequests,
    getGlobalStockInfo,
    getLocalStockInfo,
    getFullStockInfo,
  };
});

function* StockGenerator (stores, productsStock) {
  yield {
    storeId: 'current',
    storeStock: productsStock.storeStock,
    logisticStock: productsStock.logisticStock,
  };
  if (productsStock.stockInfoByAllStore && productsStock.stockInfoByAllStore.length) {
    const sibas = productsStock.stockInfoByAllStore.reduce((acc, curr) => {
      acc[curr.storeCode] = curr;
      return acc;
    }, {});
    for (const { id } of stores) {
      yield {
        storeId: id,
        storeStock: sibas[id]?.storeStock || 0,
        logisticStock: sibas[id]?.storeLogisticStock || 0,
      };
    }
  }
}

function StockType ({ storeStock, logisticStock }) {
  return {
    store: {
      stock: storeStock,
      defaultDeliveryMode (shipmentMethod) {
        return shipmentMethod === 'EXT_PALETT' ? 'extra_palett' : 'store';
      },
      token: 'awd.common.stock.types.store',
      isDisabled: () => false,
      icon: {
        name: 'dealer-pick-store-32',
        size: 32,
        fill: true,
      },
    },
    logistic: {
      stock: logisticStock,
      defaultDeliveryMode (shipmentMethod) {
        return shipmentMethod === 'EXT_PALETT' ? 'extra_palett' : 'home';
      },
      token: 'awd.common.stock.types.logistic',
      isDisabled (predictedStock = this.stock) {
        return predictedStock < 0;
      },
      icon: {
        name: 'dealer-pick-logistic-32',
        size: 32,
        fill: true,
      },
    },
  };
}
