<template>
  <span
    v-if="typeof stockStatus === 'undefined' || stockStatus"
    :title="accessibleText"
    :class="$style.stockStatus"
  >
    <span class="awSrOnlyAbs" v-text="accessibleText" />
    <span aria-hidden="true" v-text="stockStatus || '\xA0'" />
  </span>
</template>
<script>
  import { useStockInfoStore } from '~~/dealer/stores/stockInfo';

  export default {
    name: 'AdcStockStatus',
    props: {
      productId: {
        type: [Number, String],
        required: true,
      },
    },
    computed: {
      stockStatus () {
        const stockInfoStore = useStockInfoStore();
        return stockInfoStore.getGlobalStockInfo(this.productId).stockStatus;
      },
      accessibleText () {
        return `${this.$awt('awd.common.stock.status')}: ${this.stockStatus || 'loading...'}`;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.stockStatus {
  font-size: 14px;
  font-weight: 900;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  text-transform: uppercase;
  border: 1px solid $color-dash-separator;
  border-radius: 6px;
  background: $color-white;
}
</style>
