import { PT_PRICE_DISCOUNT, PT_PRICE_STICKER, PT_PRICE_LOYALTY, PT_PRICE_BUNDLE, PT_PRICE_COMBI } from '~~/common/config/priceTypes.js';

export function determinePriceType ({ variant, isShop, isLoyaltyPriceValid }) {
  if (typeof variant === 'object') {
    if (isShop && variant.stickerPrice) {
      return {
        priceType: PT_PRICE_STICKER,
        isApplicable: false,
      };
    }
    if (variant.loyaltyPrice) {
      return {
        priceType: PT_PRICE_LOYALTY,
        isApplicable: isLoyaltyPriceValid,
      };
    }
    if (isShop && variant.offerType === 'combi') {
      return {
        priceType: PT_PRICE_COMBI,
        isApplicable: false,
      };
    }
    if (isShop && variant.offerType === 'bundle') {
      return {
        priceType: PT_PRICE_BUNDLE,
        isApplicable: false,
      };
    }
    if (variant.offerType === 'discount') {
      return {
        priceType: PT_PRICE_DISCOUNT,
        isApplicable: true,
      };
    }
  }
  return {
    priceType: null,
    isApplicable: false,
  };
}
