import { mapState } from 'pinia';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import languageDataMixin from './languageDataMixin';
import { findCategoryById } from '~~/common/utils/helper';
import { webpFallback } from '~~/common/utils';
import { PCAT_ADD_PRODUCT, PUSH_COMPARE_PRODUCT } from '~~/common/plugins/aw-analytics.js';
import {
  CBDS_MODIFICATION_STRATEGY_DISALLOWS_CART_OPERATIONS,
  CBDS_UNDECIDABLE,
} from '~~/common/config/CartButtonDisplayStatus.js';
import { REVIEW_DISABLED } from '~~/common/config/review.js';
import { UnCachedProduct } from '~~/common/stores/products';
import { useModalStore } from '~~/common/stores/modal';
import { useOrderStore } from '~~/common/stores/order';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { useCategoryStore } from '~~/common/stores/category';
import { useCompareStore } from '~~/common/stores/compare';
import { useProductStore } from '~~/common/stores/product';
import { useDeliveryStore } from '~~/shop/stores/delivery';
import { useVideoStore } from '~~/common/stores/video';
import { determinePriceType } from '~~/common/utils/productPriceType.js';
import { PT_PRICE_BUNDLE, PT_PRICE_COMBI, PT_PRICE_LOYALTY, PT_PRICE_STICKER } from '~~/common/config/priceTypes.js';

export const FLAG_REMOVE_ICON = Symbol('FlagRemoveIcon');

const USED_VARIANT_PROPERTY = 'selectedVariant';

export default {
  mixins: [
    languageDataMixin,
  ],
  data () {
    return {
      showNotificationRequestModal: false,
      existingImages: [],
    };
  },
  computed: {
    ...mapState(useCompareStore, {
      compareItems: state => state.compareItems,
      maxCompareCount: state => state.maxCompareCount,
    }),
    ...mapState(useUserInterfaceStore, {
      screenRange: state => state.mediaQueries,
      screenSize: state => state.mediaQueries.query,
    }),
    ...mapState(useProductStore, {
      productDataFromStore: state => state.data,
      onSale (state) {
        return state.isPriceDiscounted(this.getVariant?.price);
      },
    }),
    ...mapState(useDeliveryStore, {
      deliveryMethod: state => state.setup,
    }),
    product () {
      return getProduct(this);
    },
    getUnCachedProduct () {
      return (this.product?.feMeta?.isCached
        ? undefined
        : new UnCachedProduct({
          productId: this.productId,
          shoppingListsContain: this.getVariant?.shoppingListsContain,
          status: this.getVariant?.cartInfo?.availability,
          isLoyaltyPriceValid: this.getVariant?.isLoyaltyPriceValid,
        })
      );
    },
    getVariant () {
      return getVariant(this.product, USED_VARIANT_PROPERTY);
    },
    getCategories () {
      return this.product.categories;
    },
    productId () {
      return getProductId(this.product);
    },
    productCategoryId () {
      return getProductCategoryId(this.product);
    },
    variantId () {
      return getVariantId(this.getVariant);
    },
    productName () {
      return getProductName(this.getVariant);
    },
    variantUnit () {
      return ['db', 'piece', 'szt.'].includes(this.getVariant.unit) ? 'db' : this.getVariant.unit; // szt. -> PL unit
    },
    isAided () {
      return this.getVariant?.aided;
    },
    eanCode () {
      return this.product?.eancode;
    },
    documents () {
      return getDocuments(this.product);
    },
    sku () {
      return this.getVariant?.sku;
    },
    images () {
      return this.getVariant?.media?.mainImage ? [].concat([this.getVariant?.media?.mainImage], this.getVariant.media.images.filter(item => item !== this.getVariant?.media?.mainImage)) : (this.getVariant?.media?.images ? this.getVariant.media.images : []);
    },
    videos () {
      return this.getVariant?.media?.videos ? this.getVariant.media.videos : [];
    },
    mainMedia () {
      return this.getVariant?.media?.mainVideo ? {
        type: 'video',
        url: this.getVariant?.media?.mainVideo,
      } : {
        type: 'image',
        url: this.getVariant?.media?.mainImage,
      };
    },
    badgesData () {
      return this.getVariant?.flags || [];
    },
    displayedFlags () {
      return getAllDisplayedFlags(this.getVariant);
    },
    isInVirtualStock () {
      return this.getVariant?.isInVirtualStock || false;
    },
    stockInfos () {
      return getStockInfos(this.getVariant);
    },
    reviewable () {
      return this.product?.reviewable;
    },
    visibleReviewBlock () {
      return isReviewVisible(this.reviewable);
    },
    reviewSum () {
      return this.product?.reviewSum;
    },
    reviewAverageValue () {
      return this.reviewSum?.average;
    },
    reviewSumCountValue () {
      return this.reviewSum?.sumCount;
    },
    discountPercentage () {
      return this.getVariant?.price?.discountDisplayPercentage;
    },
    isConvertible () {
      return this.getVariant?.loose?.convertible || false;
    },
    weightPerPieceRatio () {
      return this.getVariant?.loose?.weightPerPieceRatio || 0;
    },
    maxQuantityChoices () {
      return this.getVariant?.cartInfo?.maxQuantityChoices || 1;
    },
    quantityStepSize () {
      return this.getVariant?.cartInfo?.quantityStepSize || 0;
    },
    showedStatusMessageFull () {
      return getShowedStatusMessageFull({ availability: this.getUnCachedProduct?.status });
    },
    showedStatusMessage () {
      return this.showedStatusMessageFull?.notificationItem;
    },
    /* See: 8cbe590a9dd6f5340ca8685fbfcc22164bf966dd
    TEMPORARY REMOVED THE NOTIFICATION BUTTON
    showStockNotificationRequestLink () {
      return ['unavailable', 'notAvailableOnline'].includes(this.getVariant?.cartInfo?.availability);
    }, */
    price () {
      return this.getVariant.price;
    },
    priceUnitGross () {
      const prices = this.getUnitPriceByType('grossDiscounted');
      return `${prices.unitPrice}${(prices.unitPrice && prices.pricePerKg) ? '<br>' : ''}${prices.pricePerKg}`;
    },
    priceUnitOriginalGross () {
      const prices = this.getUnitPriceByType('gross');
      return `${prices.unitPrice}${(prices.unitPrice && prices.pricePerKg) ? '<br>' : ''}${prices.pricePerKg}`;
    },
    rollPrice () {
      if (this.getVariant?.roll?.price?.grossDiscounted) {
        return this.$price({ gross: this.getVariant.roll.price.grossDiscounted }, this.getVariant?.roll.price.currency);
      }
    },
    rollType () {
      return this.getVariant?.roll?.type;
    },
    priceOriginalGross () {
      return this.priceOriginalGrossByVariant(this.getVariant);
    },
    productPriceType () {
      return determinePriceType({
        variant: this.getVariant,
        isShop: this.isShop,
        isLoyaltyPriceValid: this.getUnCachedProduct?.isLoyaltyPriceValid,
      });
    },
    productPrices () {
      const prices = {};
      const variant = this.getVariant || {};
      const variantPInfo = variant.packageInfo || {};
      const toPrice = (val, key) => {
        if (typeof val?.[key] !== 'undefined') {
          return this.$price(
            val,
            variant.price?.currency,
            key,
          );
        } else {
          return '';
        }
      };
      const {
        priceType,
        isApplicable,
      } = this.productPriceType;
      const primaryType = isApplicable && priceType;
      const secondaryType = !isApplicable && priceType;
      if (primaryType === PT_PRICE_STICKER) {
        prices.primary = {
          main: [variant, 'stickerPrice', 'grossDiscounted'],
          unit: [variantPInfo, 'stickerUnitPrice', 'grossDiscounted'],
          unitkg: [variantPInfo, 'stickerPricePerKg', 'grossDiscounted'],
        };
      } else if (primaryType === PT_PRICE_LOYALTY) {
        prices.primary = {
          main: [variant, 'loyaltyPrice', 'grossDiscounted'],
          unit: [variantPInfo, 'loyaltyUnitPrice', 'grossDiscounted'],
          unitkg: [variantPInfo, 'loyaltyPricePerKg', 'grossDiscounted'],
        };
      } else if (primaryType === PT_PRICE_COMBI) {
        prices.primary = {
          main: [variant, 'combiPrice', 'grossDiscounted'],
          unit: [variantPInfo, 'combiUnitPrice', 'grossDiscounted'],
          unitkg: [variantPInfo, 'combiPricePerKg', 'grossDiscounted'],
        };
      } else if (primaryType === PT_PRICE_BUNDLE) {
        prices.primary = {
          main: [variant, 'bundlePrice', 'grossDiscounted'],
          unit: [variantPInfo, 'bundleUnitPrice', 'grossDiscounted'],
          unitkg: [variantPInfo, 'bundlePricePerKg', 'grossDiscounted'],
        };
      } else {
        prices.primary = {
          main: [variant, 'price', 'grossDiscounted'],
          unit: [variantPInfo, 'unitPrice', 'grossDiscounted'],
          unitkg: [variantPInfo, 'pricePerKg', 'grossDiscounted'],
        };
      }
      if (secondaryType === PT_PRICE_STICKER) {
        prices.secondary = {
          main: [variant, 'stickerPrice', 'grossDiscounted'],
          unit: [variantPInfo, 'stickerUnitPrice', 'grossDiscounted'],
          unitkg: [variantPInfo, 'stickerPricePerKg', 'grossDiscounted'],
        };
      } else if (secondaryType === PT_PRICE_LOYALTY) {
        prices.secondary = {
          main: [variant, 'loyaltyPrice', 'grossDiscounted'],
          unit: [variantPInfo, 'loyaltyUnitPrice', 'grossDiscounted'],
          unitkg: [variantPInfo, 'loyaltyPricePerKg', 'grossDiscounted'],
        };
      } else if (secondaryType === PT_PRICE_COMBI) {
        prices.secondary = {
          main: [variant, 'combiPrice', 'grossDiscounted'],
          unit: [variantPInfo, 'combiUnitPrice', 'grossDiscounted'],
          unitkg: [variantPInfo, 'combiPricePerKg', 'grossDiscounted'],
        };
      } else if (secondaryType === PT_PRICE_BUNDLE) {
        prices.secondary = {
          main: [variant, 'bundlePrice', 'grossDiscounted'],
          unit: [variantPInfo, 'bundleUnitPrice', 'grossDiscounted'],
          unitkg: [variantPInfo, 'bundlePricePerKg', 'grossDiscounted'],
        };
      } else {
        prices.secondary = {
          main: [variant, 'price', 'gross'],
          unit: [variantPInfo, 'unitPrice', 'gross'],
          unitkg: [variantPInfo, 'pricePerKg', 'gross'],
        };
      }
      for (const kk in prices) {
        const price = prices[kk];
        for (const key in price) {
          const [priceParent, priceObj, priceKey] = price[key];
          if (typeof priceParent?.[priceObj]?.[priceKey] === 'number') {
            price[key] = toPrice(priceParent[priceObj], priceKey);
            if (key === 'unit') {
              const packageUnit = priceParent.packageUnit?.toLowerCase?.();
              price[key] += `/${packageUnit === 'liter' ? 'l' : packageUnit}`;
            } else if (key === 'unitkg') {
              price[key] += '/kg';
            }
          } else {
            price[key] = '';
          }
        }
        price.units = [price.unit, price.unitkg].filter(e => e);
      }
      return prices;
    },
    notAppliedPromoPriceStr () {
      const {
        main,
        units,
      } = this.productPrices.secondary;
      return main + (units.length ? `${units.length > 1 ? '<br>' : ' ('}${units.join('<br>')}${units.length > 1 ? '' : ')'}` : '');
    },
    itemVolumeInfo () {
      return this.getVariant?.itemVolumeInfo;
    },
    rollInfo () {
      return this.rollPrice
        ? this.$awt(`aw.common.product.${this.rollType === 'redemption' ? 'redemption_price' : 'roll_price'}`, { price: this.rollPrice })
        : null;
    },
    deliveryPrice () {
      return this.getVariant?.deliveryPrice;
    },
    getBlocks () {
      return getBlocks(this.product, this.getVariant);
    },
    showDescriptionBlock () {
      return this.getBlocks.showDescriptionBlock;
    },
    showParameterListBlock () {
      return this.getBlocks.showParameterListBlock;
    },
    showNutritionBlock () {
      return this.getBlocks.showNutritionBlock;
    },
    showAllergensBlock () {
      return this.getBlocks.showAllergensBlock;
    },
    showAllergensDetailedBlock () {
      return this.getBlocks.showAllergensDetailedBlock;
    },
    showIngredientsBlock () {
      return this.getBlocks.showIngredientsBlock;
    },
    showIngredientsDetailedBlock () {
      return this.getBlocks.showIngredientsDetailedBlock;
    },
    showDocumentsBlock () {
      return this.getBlocks.showDocumentsBlock;
    },
    showDataEraserBlock () {
      return this.getBlocks.showDataEraserBlock;
    },
    showCetelemBlock () {
      return this.getBlocks.showCetelemBlock;
    },
    thumbImage () {
      return getThumbImageByVariant(this.getVariant);
    },
    image () {
      const media = this.getVariant?.media || {};

      return webpFallback([{
        url: media?.listImage || media?.mainImage || media?.images?.[0] || '',
      }]);
    },
    creditStatus () {
      return this.product?.creditStatus;
    },
  },
  methods: {
    doCompare () {
      const compareStore = useCompareStore();
      const categoryStore = useCategoryStore();
      const modalStore = useModalStore();
      // find current compare product category data
      const currentItemCategoryData = findCategoryById(
        parseInt(this.product?.categoryId),
        categoryStore.categories?.children,
      );
      // find the first compared product (already on compare list) category data
      const compareCategoryData = findCategoryById(
        parseInt(compareStore.compareItems[0]?.categoryId),
        categoryStore.categories?.children,
      );
      // check current AND compared product has same categoryID or not on current level - 1? If not, then show confirm modal.
      if (currentItemCategoryData &&
        compareCategoryData &&
        currentItemCategoryData?.parentsNameArr[currentItemCategoryData.parentsNameArr.length - 2]?.categoryId !== compareCategoryData?.parentsNameArr[compareCategoryData.parentsNameArr.length - 2]?.categoryId) {
        modalStore.showCompareCategoryConfirmModal(this.product);
      } else if (this.compareItems.length === this.maxCompareCount) { // if items equal with maxCompareItems
        modalStore.showMaxItemWarningModal();
      } else { // push product to compare store...
        compareStore.pushNewCompareItem(this.product);
        try {
          this.$awAnalytics[PUSH_COMPARE_PRODUCT]({
            productCompareAnalyticsType: PCAT_ADD_PRODUCT,
            productsArr: [this.product],
          });
        } catch (err) {
          this.$logger.error(err);
        }
        // ... and show bottom popup
        this.$emit('showPopup');
      }
    },
    videoThumbnailKey (videoId) {
      return useVideoStore().videoCache[videoId]?.thumbnail_url || 0;
    },
    getYoutubeThumbnailSources (id) {
      return useVideoStore().getYoutubeThumbnailSources(id);
    },
    asyncGetYoutubeThumbnailSources (id) {
      return useVideoStore().asyncGetYoutubeThumbnailSources(id);
    },
    priceGross (variantParam) {
      const variant = variantParam || this.getVariant;
      const price = variant?.price?.grossDiscounted;

      return {
        text: this.$price({ gross: price }, variant?.price?.currency),
        value: price,
      };
    },
    priceOriginalGrossByVariant (variantParam) {
      const variant = variantParam || this.getVariant;
      return this.$price({ gross: variant?.price?.gross }, variant?.price?.currency);
    },
    extraWeightPrice (variantParam) {
      const variant = variantParam || this.getVariant;
      return this.$price({ gross: this.product.extraWeightPrice }, variant?.price?.currency);
    },
    getUnitPriceByType (unitPriceType) {
      const variant = this.getVariant;

      const unitPrice = variant?.packageInfo?.unitPrice;
      const pricePerKg = variant?.packageInfo?.pricePerKg;

      const packageUnit = variant?.packageInfo?.packageUnit?.toLowerCase?.();
      const displayPackageUnit = packageUnit === 'liter' ? 'l' : packageUnit;
      return {
        unitPrice: unitPrice ? `${this.$price({ gross: unitPrice[unitPriceType] }, unitPrice.currency)}/${displayPackageUnit}` : '',
        pricePerKg: pricePerKg ? `${this.$price({ gross: pricePerKg[unitPriceType] }, pricePerKg.currency)}/kg` : '',
      };
    },
    priceDiscountedPeriod () {
      const config = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false,
      };
      const a = this.$date(this.price.discountValidFrom, config).replace(/\s/g, '');
      const b = this.$date(this.price.discountValidTo, config).replace(/\s/g, '');

      return {
        from: a,
        to: b,
      };
    },
    /* See: 8cbe590a9dd6f5340ca8685fbfcc22164bf966dd
    openNotificationRequestModal () {
    const modalStore = useModalStore();
      modalStore.showProductStockNotificationRequestModal(this.product);
    },
    closeNotificationRequestModal () {
    const modalStore = useModalStore();
      modalStore.hideProductStockNotificationRequestModal();
    } */
  },
};

function getProduct (props) {
  // this.productData is a prop in component where this mixin is using.
  // eg.: AwProductCard.vue, AwProductDetail.vue
  return ('productData' in props && props.productData) ? props.productData : useProductStore().data;
}

function getVariant (product) {
  return product[USED_VARIANT_PROPERTY];
}

function getProductId (product) {
  return product?.id || product?.selectedVariant?.productId;
}

function getProductName (variant) {
  return variant?.name || 'undefined';
}

function getVariantId (variant) {
  return variant?.id;
}

function getEanCode (product) {
  return product?.eancode;
}

function getBlocks (product, variant) {
  const config = useRuntimeConfig();
  const details = Array.isArray(variant?.details) ? variant.details : [];
  return {
    showDescriptionBlock: details.includes('description'),
    showParameterListBlock: details.includes('parameterList'),
    showNutritionBlock: details.includes('nutrition'),
    showAllergensBlock: details.includes('allergens'),
    showAllergensDetailedBlock: details.includes('allergensDetailed'),
    showIngredientsBlock: details.includes('ingredients'),
    showIngredientsDetailedBlock: details.includes('ingredientsDetailed'),
    showDocumentsBlock: product?.documents?.length > 0,
    showDataEraserBlock: Boolean(variant?.isDataEraserNeeded),
    showCetelemBlock: Boolean(product?.creditStatus && config.public.cetelemShopCode),
  };
}

function getStockInfos (variant) {
  return Array.isArray(variant?.stockInfos) ? variant.stockInfos : [];
}

function getProductCategoryId (product) {
  return product?.categoryId || product?.selectedVariant?.categoryId;
}

function getDocuments (product) {
  return product?.documents;
}

function getAllDisplayedFlags (variant) {
  const {
    $awt,
    $awMergedConfig: { isShop },
  } = useNuxtApp();
  const flags = [];
  if (!isShop && variant?.aided) {
    flags.push({
      flag: 'aw_aided',
      name: 'Aided',
      iconUrl: FLAG_REMOVE_ICON,
      awBadgeLongText: $awt('awd.common.aided_product'),
    });
  }
  if (!isShop && variant?.isInVirtualStock) {
    flags.push({
      flag: 'aw_kne',
      name: 'KNE',
      iconUrl: FLAG_REMOVE_ICON,
      awBadgeLongText: $awt('awd.common.kne'),
    });
  }
  flags.push(...(variant?.flags || []));
  return flags;
}

export function useProductDataMixin ({ productData }) {
  const product = computed(() => getProduct({ productData: productData.value }));
  const variant = computed(() => getVariant(product.value));
  const productId = computed(() => getProductId(product.value));
  const productName = computed(() => getProductName(variant.value));
  const variantId = computed(() => getVariantId(variant.value));
  const eanCode = computed(() => getEanCode(product.value));
  const blocks = computed(() => getBlocks(product.value, variant.value));
  const productCategoryId = computed(() => getProductCategoryId(product.value));
  const stockInfos = computed(() => getStockInfos(variant.value));
  const documents = computed(() => getDocuments(product.value));
  const priceGrossDiscountedValue = computed(() => {
    return variant.value?.price?.grossDiscounted;
  });
  const creditTypes = computed(() => {
    const creditTypes = product.value?.creditTypes;
    return Array.isArray(creditTypes) ? creditTypes : [];
  });
  const priceGrossDiscountedText = computed(() => {
    const { $price } = useNuxtApp();
    const price = priceGrossDiscountedValue.value;

    return {
      value: price,
      text: $price({ gross: price }, variant.value?.price?.currency),
    };
  });
  return {
    product,
    variant,
    productId,
    productName,
    variantId,
    eanCode,
    blocks,
    productCategoryId,
    stockInfos,
    documents,
    priceGrossDiscountedValue,
    priceGrossDiscountedText,
    creditTypes,
  };
}

export function getThumbImageByVariant (variantParam) {
  const media = variantParam?.media || {};
  if (media.mainVideo) {
    return {
      videoId: media.mainVideo,
    };
  }
  return webpFallback([
    {
      url: media.listImage || media.mainImage || media.images?.[0] || '',
    },
  ]);
}

export function getShowedStatusMessageFull ({ availability }) {
  const { $awMergedConfig: { isShop } } = useNuxtApp();
  const {
    getModStrategy,
    cartButtonDisplayStatuses,
  } = useOrderStore();
  if (!isShop && getModStrategy) {
    return cartButtonDisplayStatuses[CBDS_MODIFICATION_STRATEGY_DISALLOWS_CART_OPERATIONS];
  }

  if (isShop && !useDeliveryStore().setup && availability === CBDS_UNDECIDABLE) {
    return undefined;
  } else {
    return cartButtonDisplayStatuses[availability];
  }
}

export function isReviewVisible (reviewable) {
  return reviewable !== REVIEW_DISABLED;
}
